<template>
	<div class="table__block--content user-profile__block--content">
		<div class="user-profile__block--update">
			<button class="user-profile--update" :disabled="isUpdate" @click="apiGetUserQuota">
				<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
				{{ $t('commands.update') }}
			</button>
		</div>

		<v-data-table
			:headers="getHeaders"
			:items="list"
			:loading="false"
			:options.sync="options"
			:server-items-length="totals"
			:footer-props="{
				'items-per-page-options': [5, 10, 20, 30, 40, 50],
			}"
			@update:options="paginationUserQuota"
		>
			<template v-slot:item.region="{ item }">
				{{ item.region?.title }}
			</template>
			<template v-slot:item.iin_bin="{ item }">
				{{ item.iin_bin }}
			</template>
			<template v-slot:item.pond="{ item }">
				{{ item.pond?.title }}
			</template>
			<template v-slot:item.total="{ item }">
				{{ getFishesSum(item.fishes) | formatNumber }}
			</template>
			<template v-slot:item.fish="{ header, item }">
				{{ findFish(item.fishes, header.fish_id) }}
			</template>
			<v-alert slot="no-results" :value="true" color="error">
				{{ $t('system_message.search') }}
			</v-alert>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { api } from '@/boot/axios'

export default {
	name: 'AccontQuota',
	props: {
		userId: Number,
	},
	data() {
		return {
			headers: [
				{ text: 'Id', value: 'id', width: '50px' },
				{ text: this.$t('headers.iin_bin'), value: 'iin_bin', width: '120px' },
				{ text: this.$t('headers.regions'), value: 'region' },
				{ text: this.$t('headers.pond'), value: 'pond' },
				{ text: `${this.$t('headers.total')}, кг`, value: 'total' },
			],
			list: [],
			totals: 0,
			isUpdate: true,
			options: {
				itemsPerPage: 5,
				page: 1,
			},
		}
	},
	computed: {
		...mapGetters(['_getFishes']),
		getHeaders() {
			return [
				...this.headers,
				...this._getFishes
					.filter((fish) => {
						return this.list.some((item) => {
							return item.fishes.some((f) => {
								return f.fish_id === fish.id
							})
						})
					})
					.map((fish) => {
						return { text: `${fish.name}, кг`, value: 'fish', fish_id: fish.id, width: '150px' }
					}),
			]
		},
		getFishesSum() {
			return (fishes) => {
				return fishes.reduce((summ, fish) => {
					summ += fish.value
					return summ
				}, 0)
			}
		},
	},
	mounted() {
		this._fetchFishes()
	},
	methods: {
		...mapActions(['_fetchFishes']),
		catchError(error) {
			if (error?.response?.status == 500) {
				this.errorMessage.status = 500
				this.errorMessage.text = this.$t('system_message.500')
			}
			if (error?.response?.status == 401) {
				this.errorMessage.status = 401
				this.errorMessage.text = error.response.data.error_message
				this.$router.push('/' + this.$i18n.locale + '/login')
			}
			if (error?.response?.status == 422) {
				this.errorMessage.status = 422
				this.errorMessage.text = error.response.data.error_message
			}
			if (error?.response?.status == 403) {
				this.errorMessage.status = 403
				this.errorMessage.text = error?.response?.data?.message
			}
		},
		paginationUserQuota(options) {
			this.apiGetUserQuota(options.page, options.itemsPerPage)
		},
		async apiGetUserQuota(page = 1, itemsPerPage = 10) {
			try {
				this.isUpdate = true
				let queries = `page=${page}&per_page=${itemsPerPage}`
				const response = await api.get(`/user/${this.userId}/quota?${queries}`, {
					params: {},
				})
				if (response.data) {
					this.list = response?.data?.data
					this.totals = response?.data?.total
				}
			} catch (error) {
				this.catchError(error)
			} finally {
				this.isUpdate = false
			}
		},
		findFish(fishs, id) {
			return this.$options.filters.formatNumber(
				fishs.find((fish) => fish.fish_id == id)?.value || '0',
			)
		},
	},
}
</script>
